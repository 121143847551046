.sidepanel {
    
    background-color: #e6eef1;
    padding: 1vh;
    width: 25vw;
    font-size: 0.8em;
    overflow: auto;
}

.sidepanel-title {
    padding: 0px;
}

.sidepanel-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 17px;
    padding: 2vh;
    margin: 0 auto;
}

.sidepanel-label {
    text-align: right;
    font-weight: 700;
}

.sidepanel-divider {
    grid-column: span 2;
    padding-top: 5px;
    margin-bottom: 10px;
    margin-left: 7px;
    margin-right: 7px;    
    border-bottom: 1px solid darkgray;
}