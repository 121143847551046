.header {    
    background-color: white;
    padding: 1.5vh;
    
    display: grid;
    grid-template-columns: 4vw 4vw 1fr 5vw 3vw;

    border-width: 2px;
    border-color: darkgray;
    border-style: none none solid none;
}