.waitinglist-fullpage {

    /* background */
    background-color: #009eb0;
    
    /* full page */
    height: 90vh;
    padding: 5vh;
  }
  
.waitinglist-content {
  
    background-color: white;
    overflow: auto;
    padding: 2vh;
    height: 80vh;
  }