.title {
    background-color: white;
    padding: 2vh;
    font-weight: 900;
    font-size: x-large;
    text-align: center;
}

.title > a:link {
    color: black;
    text-decoration: none;
}
  
.title > a:visited {
    color: black;
    text-decoration: none;
}