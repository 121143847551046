.scorecard {
    height: 90px;
    align-content: center;
    text-align: center;
    vertical-align: middle;
    border-radius: 4px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.scorecard.expanded {
    height: auto;
}

.scorecard-code {
    font-weight: 900;
    font-size: 0.85em;
    display: block;
    padding: 1em 0 1.5em 0;
}

.scorecard-title {
    font-weight: 600;
    font-size: 0.85em;
    display: block;
}

.scorecard-code svg {
    position: relative;
    top: 7px;
}
