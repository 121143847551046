.legenditem {

    background-color: white;
    padding: 1vh;
    font-size: x-small;

    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 1vw;
    margin-left: 10px;
}

.legenditem-key {

    width: 35px;
    height: 30px
}

.legenditem-icon svg {
    position: relative;
    top: 6px;
    left: 9px;
    width: 0.7em;
    height: 0.7em;
}