.fullpage {

    /* background */
    background-color: #009eb0;
    
    /* full page */
    height: 90vh;
    padding: 5vh;
  }
  
  .content {
  
    background-color: white;
    
    display: grid;
    grid-template-columns: min-content 1fr;
  
    height: 76vh;
  }

  .inner-content {
  
    background-color: white;
    
    display: grid;
    grid-template-columns: 1fr min-content;
  
    height: 78vh;
  }