.legend {

    background-color: white;

    display: grid;
    grid-column: span 2;
    align-self: end;
}

.legend-content {

    background-color: white;
    padding: 1vh;

    font-size: 0.8em;
    text-align: left;    

    border-width: 2px;
    border-color: darkgray;
    border-style: solid none none none;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1.5vw;
}