.detailpanel {
    background-color: white;
    padding: 2vh;
    width: 20vw;
    font-size: 0.9em;
    overflow: auto;
}

.detailpanel h2 {
    padding: 8px 0;
    margin: 0;
}