.scorecardgrid {
    
    background-color: white;
    padding: 2vh;
    overflow: auto;
    font-size: 0.9em;
}

.scorecardgrid-title {

    padding: 0px;
}

.scorecardgrid-content {

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
    grid-gap: 15px;
    padding: 1vh;
    margin: 0 auto;
}